var languageConfigs = {
  asset: {
    assetName: "Asset name",
    assetCode: "Product code",
    description: "Description",
    type: "Asset type",
    amount: "Amount in stock",
    price: "Price per item",
    asset: "Stationary asset",
    consumable: "Consumable asset",
    advancesetting: "Advance settings",
    instock: "Stock",
    create: "Create new asset",
    createAssetConfirmMessage:
      "After creation you cannot change asset type. Do you want to continue?",
    delete: "Delete asset",
    deleteAssetConfirmMessage:
      "Delete this asset cannot be undone. Do you want to continue?",
    showQr: "Show QR code",
    hideQr: "Hide QR code",
    importInventory: "Update in-stock amount",
    amountChange: "Amount increase/decrease",
    reasonChange: "Update note",
    consumeRequest: "Usage request",
    consumeAmmount: "Item amount",
    consumeDescription: "Usage description",
    consumePrice: "Usage price",
    consumePriceEach: "Price per item",
    consumeAmmountShort: "Amount",
    addItem: "Choose",
    confirm: "Confirm",
    confirmMessage: "Are are sure to complete this action?",
  },
  user: {
    profile: "Peronal Profile",
    setting: "Setting",
    logout: "Logout",
    phone: "Phone",
    fullName: "Full Name",
    dob: "Birthdate",
    gender: "Gender",
    email: "Email",
    address: "Address",
    occupation: "Occupation",
    country: "Country",
    call: "Call",
    password: "Password",
    profileUrl: "Profile URL",
  },
  booking: {
    time: "Booking time",
    price: "Estimated cost",
    complaint: "Travel purpose",
    note: "Note",
    myappointment: "My Calendar",
  },
  episode: {
    myepisode: "My task",
    exam: "Report content",
    info: "Task info",
    checkintime: "Check-in time",
    clinicaldatanotavailable: "Report content not available",
    success: "Task Success",
    canceled: "Task Canceled",
  },
  clinic: {
    description: "Description",
    tax: "Tax Number",
    name: "Organization Name",
    hotline: "Hotline",
  },
  service: {
    name: "Task title",
    price: "Estimated cost",
    description: "Report content",
    noproviders: "No supervisors yet.",
  },
  home: {
    welcome: "Welcome",
    haveaniceday: "Have a nice day at work!",
    report: "Report",
    event: "Calendar",
    episode: "Task",
    notification: "Activities",
  },
  auth: {
    or: "Or",
    emailorphone: "Email / Phone",
    login: "Login",
    signup: "Signup",
    verifyphone: "Verify your phone number",
    sendcode: "Send code",
    alreadyhaveaccount: "Already have an account?",
    donthaveaccount: "Dont have an account?",
  },
  register: {
    editlogo: "Edit logo",
    verifyCode: "Verify Code",
    becomedoctor: "Register supervisor account",
    registerclinic: "Register Organization",
    registeraccount: "Register Account",
    register: "Register",
    continue: "Continue",
    additionalinfo: "Additional Info",
    male: "Male",
    female: "Female",
    completed: "Completed",
    startusingapp: "Start using app",
    message1:
      "These information is used to provide best experience and services for our users.",
    message2: "Thank you for signing up Account!",
    message3:
      "We will contact you within 24h to verify. In the meatime you can start using our apllication.",
  },
  common: {
    galery: "Image galery",
    upload: "Upload",
    sale: "Sale",
    forSale: "For sale",
    saleHighlight: "Sale highlight (short)",
    category: "Categories",
    back: "Back",
    isSaleOrder: "Setup as sale order form",
    saleOrder: "Sale order form",
    totalPrice: "Total Price",
    search: "Search",
    checkin: "Assign task",
    appoint: "Appoint",
    choose: "Choose",
    selecttoview: "Select an item to view",
    selectdatetime: "Select date & time",
    create: "Create",
    info: "Information",
    detail: "Detailed information",
    select: "Select",
    datetime: "Date & time",
    confirm: "Confirm",
    confirmed: "Confirmed",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    finish: "Finish",
    selectfile: "Select file",
    today: "Today",
    at: "At",
    invite: "Invite",
    user: "User",
    notprovide: "Not provided",
    nodata: "No data found",
    awesomenodata: "Awesome! No activities yet.",
    lastvisits: "Latest tasks",
    verifyUpdate: "Starting application. Please wait a moment...",
    serverunavailable: "Server is under maintainance. Please come back later.",
    backtohome: "Back to home",
    timeStart: "Time start",
    timeEnd: "Time end",
    examcontent: "Report content",
    clinicaldata: "Supervisor note",
    account: "Account",
    report: "Report",
    month: "Month",
    rebooking: "Re-appoint",
    open: "Open website",
    title: "Title",
    headline: "Headline",
    share: "Share",
    unshare: "Unshare",
    confirmShare:
      "Share this information to be viewed by other people. Do you wish to continue?",
    confirmUnshare:
      "Unshare this information, people cannot see this information. Do you wish to continue?",
    change: "Change",
    oldPassword: "Old password",
    newPassword: "New password",
    retypePassword: "Re-type new password",
    changePasswordFailMessage: "Change password failed!",
    changePasswordSuccessMessage:
      "Change password success! Please login again.",
  },
  nav: {
    home: "Dashboard",
    patient: "Personnel",
    doctor: "Supervisor",
    booking: "Calendar",
    episode: "Task",
    search: "Search",
    setting: "Setting",
    clinic: "Organization",
    service: "Task",
  },
  cardTitle: {
    clinic: "My Organization",
    clinics: "Organizations",
    patients: "Personnels",
    bookings: "Calendar",
    episodes: "Tasks",
    doctors: "Supervisors",
    services: "Tasks",
    assets: "Assets",
  },
  error: {
    phonealreadyinuse: "Phone number already in use, please use it to login!",
    verifycodefailed: "Cannot send verification code to this number!",
    retrysendcode: "Retry with a different phone number",
  },
};
export default languageConfigs;
